<template>
  <div class="leader-page">
    <!-- 上部分四个饼图 -->
    <pie></pie>
    <div class="project-data-wrapper">
      <proTitle></proTitle>
      <div class="project-data-container flex-layout">
        <div class="left-part">
          <!-- 项目概况 -->
          <div class="modules">
            <div class="flex-align-between">
              <h4 class="module-title flex-align">项目概况</h4>
              <p @click="gopreview" class="report-title">绩效报告</p>
            </div>
            <p class="module-introduce">{{ $store.state.user.currentProject.introduction || '无' }}</p>
          </div>
          <!-- 能力图谱 -->
          <div class="modules">
            <h4 class="module-title flex-align" style="margin-top:50px">能力图谱</h4>
            <div class="ability-select">
              <!-- 选择身份 -->
              <el-select v-model="abilityRole" class="select-1st" size="mini" @change="handleRoleChange">
                <el-option :value="1" label="教师"></el-option>
                <el-option :value="2" label="学生"></el-option>
              </el-select>
              <!-- 选择套餐 -->
              <el-select v-model="abilityMealValue" class="select-2nd" size="mini">
                <el-option v-for="item in abilityMeal" :key="item.id" :label="item.title" :value="item.id">
                </el-option>
              </el-select>
            </div>
            <!-- 能力图谱雷达图 -->
            <!-- <abilityRadar :abilityRole="abilityRole" :abilityMealID="abilityMealValue" :projectID="Number(item.id)"> -->
            <abilityRadar :abilityRole="abilityRole" :abilityMealID="abilityMealValue"
              :projectID="Number($store.state.user.currentProject.id)">
            </abilityRadar>
          </div>
        </div>

        <div class="middle-part">
          <el-timeline>
            <el-timeline-item size="normal" v-for="(item, index) in 10" :key="index"
              :color="index <= progress ? '#1ED763':''" :class="[index < progress ?  'finish': 'unfinish']">
              {{ `${item}0%` }}
            </el-timeline-item>
          </el-timeline>
        </div>

        <div class="right-part">
          <!-- 数据统计 -->
          <div class="modules data-count flex-align-around">
            <div class="module" v-for="(item,name,index) in countAvg" :key="index" v-show="item">
              <p class="data-num">
                {{countAvg && item}}
                <span class="f16">%</span>
              </p>
              <p class="data-name">{{countAvgNameMap[name]}}</p>
            </div>
          </div>
          <!-- 课程情况 -->
          <!-- <div class="modules course-state" v-show="item.courseShow"> -->
          <div class="modules course-state" v-show="countAvg.courseAvg != 0">
            <div class="title-part flex-align-between">
              <h4 class="flex-align">课程情况</h4>
              <el-select v-model="courseDataType" class="select" size="mini">
                <!-- @change="handleCourseTypeChange" -->
                <el-option :value="1" label="按月"></el-option>
                <el-option :value="0" label="按天"></el-option>
              </el-select>
            </div>
            <!-- 课程情况柱状图 -->
            <!-- <courseBar :courseType="courseDataType" :projectID="item.id" @shuttle="handleCourseBar"></courseBar> -->
            <courseBar :courseType="courseDataType" :projectID="$store.state.user.currentProject.id"
              @shuttle="handleCourseBar"></courseBar>
          </div>

          <!-- 活动情况 -->
          <!-- <div class="modules work-state" v-show="item.activityShow"> -->
          <div class="modules work-state" v-show="countAvg.activityAvg != 0">
            <div class="title-part flex-align-between">
              <h4 class="flex-align">活动情况</h4>
              <el-select v-model="activityDataType" class="select" size="mini">
                <el-option :value="1" label="按月"></el-option>
                <el-option :value="0" label="按天"></el-option>
              </el-select>
            </div>
            <!-- 作业情况柱状图 -->
            <!-- <activityBar :courseType="activityDataType" :projectID="item.id" @shuttle="handleActivityBar"> -->
            <activityBar :courseType="activityDataType" :projectID="$store.state.user.currentProject.id"
              @shuttle="handleActivityBar">
            </activityBar>
          </div>

          <!-- 作业情况 -->
          <!-- <div class="modules work-state" v-show="item.workShow"> -->
          <div class="modules work-state" v-show="countAvg.homeworkAvg != 0">
            <div class="title-part flex-align-between">
              <h4 class="flex-align">作业情况</h4>
              <el-select v-model="workDataType" class="select" size="mini">
                <el-option :value="1" label="按月"></el-option>
                <el-option :value="0" label="按天"></el-option>
              </el-select>
            </div>
            <!-- 作业情况柱状图 -->
            <!-- <workBar :courseType="workDataType" :projectID="item.id" @shuttle="handleWorkBar"></workBar> -->
            <workBar :courseType="workDataType" :projectID="$store.state.user.currentProject.id"
              @shuttle="handleWorkBar"></workBar>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    mapState
  } from "vuex";
  import pie from "./component/pie"
  import abilityRadar from "./component/ability-radar"
  import courseBar from "./component/course-bar"
  import workBar from "./component/work-bar"
  import activityBar from "./component/activity-bar"
  export default {
    components: {
      abilityRadar,
      courseBar,
      workBar,
      activityBar,
      pie
    },
    data() {
      return {
        tabsList: [], //tab栏列表
        index: 1,
        progress: 7, //进度条 进度
        abilityRole: 1, //能力图谱身份
        abilityMeal: [], //能力图谱套餐
        abilityMealValue: 0, //能力图谱套餐选中id
        courseDataType: 1, //请求课程数据value 0 按天 1按月
        workDataType: 1, //请求作业数据value 0 按天 1按月
        activityDataType: 1,
        countAvg: { //数据统计
          courseAvg: 0,
          activityAvg: 0, //活动 
          homeworkAvg: 0 //作业
        },
        countAvgNameMap: { //数据统计中文名称对照表
          homeworkAvg: '作业平均完成率',
          courseAvg: '课程平均完成率',
          activityAvg: '活动平均参与率',
        },
      };
    },
    computed: {
      ...mapState({
        phone: state => state.user.userInfo.phone,
      })
    },
    methods: {
      //跳转绩效报告页面
      gopreview() {
        // console.log(this.activeName);
        if (this.phone == '13812341235') {
          this.$store.commit('getPdfUrl', 'adce586f2d377ed187521d932f3b9cae_3332565_pdf')
          this.$store.commit('getPdfName', '内蒙古中小学教师“培训+跟岗+实践”项目总结报告')

          this.$router.push(`/pdf/adce586f2d377ed187521d932f3b9cae_3332565_pdf`)
          return;
        }


        let pathMap = {
          85: "/preview", //"名校联盟双师课堂",
          96: "/innerMongolia", //"内蒙古",
          93: "/kindergarten", //"名校联盟双师课堂",
        };

        if (Object.keys(pathMap).includes(this.activeName)) {
          this.$router.push(pathMap[this.activeName]);
        } else {
          this.$alert("暂未开放！", "提示", {
            confirmButtonText: "确定",
          });
        }

      },


      // 处理课程柱状图回传数据
      handleCourseBar(data) {
        // console.log('课程',data)
        // this.tabsList.map((item) => {
        //   if (data.id === item.id) {
        //     item.courseShow = Object.keys(data.barData).length ? true : false
        //   }
        // })
        // console.log(this.tabsList);
      },
      handleWorkBar(data) {
        // console.log('作业',data)
        // this.tabsList.map((item) => {
        //   if (data.id === item.id) {
        //     item.workShow = Object.keys(data.workData).length ? true : false
        //   }
        // })
        // console.log(this.tabsList);
      },
      handleActivityBar(data) {
        //  console.log('活动',data)
        // this.tabsList.map((item) => {
        //   if (data.id === item.id) {
        //     item.activityShow = Object.keys(data.activityData).length ? true : false
        //   }
        // })
        //  console.log(this.tabsList);
      },
      // 获取能力图谱身份
      async getAbilityByAdmin() {
        let resData = await this.$Api.Home.getAbilityByAdmin();
        // console.log(resData);
        this.abilityMeal = resData.data[this.abilityRole];
        this.abilityMealValue = this.abilityMeal[0].id
      },
      // 改变能力图谱身份
      handleRoleChange(val) {
        this.getAbilityByAdmin();
      },
      // 获取统计数据
      async getCountAvg() {
        // let resData = await this.$Api.Home.getCountAvg(this.activeName);
        let resData = await this.$Api.Home.getCountAvg(this.$store.state.user.currentProject.id);
        // this.countAvg = resData;
        for (const key in this.countAvg) {
          if (Object.hasOwnProperty.call(this.countAvg, key)) {
            this.countAvg[key] = resData[key];
          }
        }
        //  console.log(this.countAvg);
      },
    },
    mounted() {
      this.getAbilityByAdmin();
      this.getCountAvg();
    },

  };
</script>

<style lang="less" scoped>
  .leader-page {

    .project-data-wrapper {
      margin-bottom: 48px;
      padding: 0 30px;

      .project-data-container {
        margin-top: 6px;

        .left-part {
          width: 45%;

          .report-title {
            width: 100px;
            height: 30px;
            text-align: center;
            font-weight: bold;
            background-color: #508EF9;
            box-shadow: 0px 5px 13px 0px rgb(22 115 255 / 28%);
            border-radius: 5px;
            font-size: 14px;
            font-family: PingFang SC;
            line-height: 30px;
            color: #ffffff;
            cursor: pointer;
          }

          .module-title {
            font-size: 14px;
            font-weight: bold;

            &::before {
              content: ' ';
              display: inline-block;
              margin-right: 13px;
              height: 15px;
              width: 3px;
              border-radius: 2px;
              background-color: #508EF9;
            }
          }

          .module-introduce {
            margin-top: 20px;
            line-height: 22px;
            font-size: 12px;
            color: #666;
            text-indent: 2em;
          }

          .ability-select {
            margin-left: 0px;
            padding-top: 30px;

            .select-1st {
              width: 82px;
              margin-right: 14px;

            }

            .select-1st /deep/.el-input__inner,
            .select-2nd /deep/.el-input__inner {
              border-radius: 25px;
            }
          }
        }

        .middle-part {
          margin: 10px 6px 0 20px;
        }

        .right-part {
          width: 45%;

          .data-count {
            background: rgba(35, 115, 236, 0.04);
            padding: 28px 0 21px;

            .module {
              width: 33.33%;
              text-align: center;
            }


            .data-num {
              font-size: 20px;
              font-weight: bold;
              color: #508EF9;
            }

            .data-name {
              font-size: 12px;
              color: #333333;
              transform: scale(0.9);
            }

            .module:nth-child(2) {
              .data-num {
                color: #FF6C60;
              }
            }

            .module:nth-child(3) {
              .data-num {
                color: #5CDC9B;
              }
            }


          }

          .title-part {
            margin: 37px 0 13px;

            h4 {
              font-size: 14px;
              font-weight: bold;

              &::before {
                content: ' ';
                display: inline-block;
                margin-right: 13px;
                height: 15px;
                width: 3px;
                border-radius: 2px;
                background-color: #508EF9;
              }
            }

            .select {
              width: 75px;
              margin-right: 6px;

              /deep/.el-input__inner {
                border-radius: 25px;
              }
            }
          }
        }
      }
    }

    @media screen and (min-width: 1250px) {
      .project-data-wrapper {
        .project-data-container {
          .left-part {
            .ability-select {
              margin-left: 16px;
            }
          }

          .middle-part {
            margin: 10px 18px 0 24px;
          }

          .right-part {
            .data-count {
              .data-num {
                font-size: 24px;
              }

              .data-name {
                font-size: 12px;
                transform: scale(1);
              }
            }
          }
        }
      }
    }
  }

  // 时间线
  /deep/ .el-timeline-item {
    padding-bottom: 95px;

    .el-timeline-item__node {
      width: 10px;
      height: 10px;
    }

    .el-timeline-item__node::after {
      content: ' ';
      position: absolute;
      top: -2px;
      left: -2px;
      border-radius: 50%;
      width: 14px;
      height: 14px;
      background-color: rgba(172, 172, 172, 0.25);
    }

    &.finish .el-timeline-item__node::after,
    &.finish+&.unfinish .el-timeline-item__node::after {
      background-color: rgba(30, 215, 99, 0.25);
    }

    .el-timeline-item__wrapper {
      padding-left: 12px;
      top: -5px;
    }

    .el-timeline-item__content {
      color: #A6A6A6;
      font-size: 12px;
      transform: scale(.9)
    }

    &.finish .el-timeline-item__content {
      color: #1ED763;
    }

    .el-timeline-item__tail {
      left: 3px;
      top: 12px;
      height: 88%;
    }

    &.finish .el-timeline-item__tail {
      top: 12px;
      left: 3px;
      height: 88%;
      border-left: 2px solid #1ED763;
    }
  }
</style>